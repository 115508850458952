/**
* CSS file to hold styles that apply across the application
*/

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap");

html,
body {
  scroll-behavior: smooth;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
}

/* Add these styles to your CSS file */

.fade-in {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.headerer {
  gap: 1rem;
}

.footerer {
  gap: 1.5rem;
}

.list-u {
  columns: 1;
  font: 1rem;
  margin: 10px;
  gap: 2rem;
}

.portimage {
  max-width: 0;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 0;
}

section img.background {
  max-width: 0;
}

section img.background2 {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

section img.background3 {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 0.8;
  margin: 0;
  padding-top: 90%;
}

h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
  padding-top: 1rem;
  white-space: "nowrap";
}

h3 {
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}

p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

p.large {
  font-size: 14px;
}

p.small {
  font-size: 14px;
}

hr {
  margin: 20px;
}

li {
  line-height: 1.5;
  padding: 5px;
  display: list-item;
  align-items: justify;
}

li::marker {
  color: #4e567e;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  display: -moz-deck;
  content-visibility: false;
  grid-template-columns: 200px 200px;
}

.container .box {
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
  margin: 20;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

@media (min-width: 600px) {
  body {
    flex-wrap: nowrap;
  }
  .portimage {
    max-width: 100%;
  }
  .headerer {
    gap: 2rem;
  }
  .footerer {
    gap: 2.5rem;
  }
  .list-u {
    columns: 2;
    font: 1.25rem;
    margin: 2rem 3rem;
    gap: 3rem;
  }
  section.min-height {
    min-height: 100vh;
  }  
  section img.background {
    max-width: 100%;
    height: 100%;
    left: 50%;
    object-fit: cover;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    width: 100vmax;
    z-index: -1;
  }
  section img.background2 {
    max-width: 0;
  }
  h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 7rem;
    font-weight: 400;
    line-height: 0.8;
    margin: 0;
    padding-top: 100px;
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding-top: 1rem;
    white-space: nowrap;
  }
  
  h3 {
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0;
  }

  p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
  }

  p.large {
    font-size: 24px;
  }

  p.small {
    font-size: 15px;
  }

  hr {
    margin: 2.5rem;
  }

  li {
    line-height: 1.7;
  }

  li::marker {
    color: #4e567e;
  }

  .container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 330px 300px;
    margin: 20px auto;
  }
  
  .container .box {
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 16px;
    text-align: center;
  }  

}